<template>
  <div class="van-main has-fixed-btn">
    <div class="van-common-detail">
      <van-tabs v-model="activeName">
        <van-tab name="info" title="报关行信息">
          <van-cell-group title="企业信息">
            <van-cell title="企业全称" :value="utils.isEffectiveCommon(funderdetail.orgName)" />
            <van-cell title="统一社会信用代码" :value="utils.isEffectiveCommon(funderdetail.orgNo)" />
            <van-cell title="企业简称" :value="utils.isEffectiveCommon(funderdetail.orgShortName)" />
            <van-cell title="注册资本（万元）" :value="utils.isEffectiveCommon(funderdetail.orgRegCap)" />
            <van-cell title="成立日期" :value="utils.isEffectiveCommon(funderdetail.establishDate)" />
            <van-cell title="企业联系人" :value="utils.isEffectiveCommon(funderdetail.enterpriseContact)" />
            <van-cell title="联系电话" :value="utils.isEffectiveCommon(funderdetail.orgContactPhone)" />
            <van-cell title="服务口岸" :value="utils.isEffectiveCommon(funderdetail.servicePort)" />
            <van-cell title="注册地址" :value="utils.newProvinceCityFormatter([funderdetail.orgRegProvince,funderdetail.orgRegCity, funderdetail.orgRegXq, funderdetail.orgRegDetail])" />
            <van-cell title="办公地址" :value="utils.newProvinceCityFormatter([funderdetail.orgWorkProvince,funderdetail.orgWorkCity, funderdetail.orgWorkXq, funderdetail.orgWorkDetail])" />
            <van-cell title="公司简介" :value="utils.isEffectiveCommon(funderdetail.companyProfile)" />
            <van-cell title="企业营业执照">
              <van-uploader v-model="businessLicenseList" :show-upload="false" :deletable="false" disabled @click-preview="preview" />
            </van-cell>
          </van-cell-group>
          <van-cell-group title="企业法定代表人信息">
            <van-cell title="法定代表人姓名" :value="utils.isEffectiveCommon(funderdetail.businessName)" />
            <van-cell title="法定代表人联系电话" :value="utils.isEffectiveCommon(funderdetail.bizPhone)" />
            <van-cell title="证件类型" :value="utils.statusFormat(funderdetail.businessSnType,'nongtoutypeCertificateType')" />
            <van-cell title="证件号码" :value="utils.isEffectiveCommon(funderdetail.businessSn)" />
            <van-cell title="电子邮箱" :value="utils.isEffectiveCommon(funderdetail.businessEmail)" />
            <van-cell title="证件照片" class="certificate-photo">
              <van-uploader v-model="fileBusinessImgsList" :show-upload="false" :deletable="false" disabled @click-preview="preview" />
            </van-cell>
          </van-cell-group>
          <van-cell-group title="资质及证明">
            <van-cell title="图片列表" class="certificate-photo">
              <van-uploader v-model="selectedOptionsList" :show-upload="false" :deletable="false" disabled @click-preview="preview" />
            </van-cell>
          </van-cell-group>
        </van-tab>
        <van-tab name="delivery" title="报价方案">
          <div class="table goods-table">
            <table class="inner-goods-table">
              <thead>
                <tr>
                  <th>费用类型</th>
                  <th>收费标准</th>
                  <th>备注</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="item in accountInfoVoList" :key="item.id">
                  <td>{{ utils.isEffectiveCommon(item.expenseType) }}</td>
                  <td>{{ utils.isEffectiveCommon(item.chargingStandard) }}</td>
                  <td>{{ utils.isEffectiveCommon(item.remark) }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </van-tab>
        <van-tab name="audit" title="审批意见">
          <workflow-audit v-if="Number($route.query.taskViewMode)!==0" page-key="customs_broker_alter" :business-key="funderdetail.enterpriseId" :proc-inst-id="$route.query.procInstId" :variable="{businessId:funderdetail.enterpriseId,motif:funderdetail.orgShortName}" :view-mode="true" @confirmAudit="utils.backReturn($route.query.messageType)" />
          <workflow-audit v-else page-key="customs_broker_alter" :business-key="funderdetail.enterpriseId" :task-id="$route.query.taskId" :proc-inst-id="$route.query.procInstId" :variable="{businessId:funderdetail.enterpriseId,motif:funderdetail.orgShortName}" @confirmAudit="utils.backReturn($route.query.messageType)" />
        </van-tab>
      </van-tabs>
    </div>
    <van-row class="fixed-btn-bottom" gutter="20">
      <van-button type="info" color="#1373CC" block text="返回" @click="utils.backReturn($route.query.messageType)" />
    </van-row>
  </div>
</template>

<script>
import { Tab, Tabs, Cell, CellGroup, Row, Button, Uploader, Checkbox, CheckboxGroup } from 'vant'
import WorkflowAudit from '@/components/workflow-audit'
// import fileList from '@/components/file-list'
export default {
  components: {
    WorkflowAudit,
    [Tab.name]: Tab,
    [Tabs.name]: Tabs,
    [CellGroup.name]: CellGroup,
    [Cell.name]: Cell,
    [Row.name]: Row,
    [Button.name]: Button,
    [Uploader.name]: Uploader,
    [Checkbox.name]: Checkbox,
    [CheckboxGroup.name]: CheckboxGroup
  },
  data () {
    return {
      activeName: 'info',
      funderdetail: {},
      quotaDetail: {},
      qualificationList: [],
      pickUpInfoVoList: [],
      goodsList: [],
      vehiclesInfoVoList: [],
      totalApplyNumber: 0,
      productName: '',
      loanData: [],
      collectionAmountSum: 0,
      amountPaid: 0,
      appDownList: [],
      businessLicenseList: [],
      fileBusinessImgsList: [],
      selectedOptionsList: [],
      accountInfoVoList: []
    }
  },
  created () {
    this.getFunderChangeDetails()
  },
  methods: {
    // 处理文件数组
    getFileName (fileUrl) {
      const splitList = fileUrl ? fileUrl.split('/') : []
      return splitList[splitList.length - 1] || ''
    },
    getFunderChangeDetails () {
      this.utils.loading()
      this.api.task.fundManagement.FunderChange(this.$route.query.id).then(result => {
        this.funderdetail = result.data.value
        this.getPageList()
        this.schemeList()
        if (this.funderdetail.orgLicenseImgs) {
          this.businessLicenseList = [{
            fileUrl: this.funderdetail.orgLicenseImgs,
            url: this.funderdetail.orgLicenseImgs,
            src: this.funderdetail.orgLicenseImgs,
            fileName: this.getFileName(this.funderdetail.orgLicenseImgs),
            name: this.getFileName(this.funderdetail.orgLicenseImgs),
            id: 0
          }]
        } else {
          this.businessLicenseList = []
        }
        if (this.funderdetail.businessSnPreImgs) {
          this.fileBusinessImgsList = [...[], ...[{
            fileUrl: this.funderdetail.businessSnPreImgs,
            url: this.funderdetail.businessSnPreImgs,
            src: this.funderdetail.businessSnPreImgs,
            fileName: this.getFileName(this.funderdetail.businessSnPreImgs),
            name: this.getFileName(this.funderdetail.businessSnPreImgs),
            id: 0
          }]]
        } else {
          this.fileBusinessImgsList = []
        }
        if (this.funderdetail.businessSnBackImgs) {
          this.fileBusinessImgsList = [...this.fileBusinessImgsList, ...[{
            fileUrl: this.funderdetail.businessSnBackImgs,
            url: this.funderdetail.businessSnBackImgs,
            src: this.funderdetail.businessSnBackImgs,
            fileName: this.getFileName(this.funderdetail.businessSnBackImgs),
            name: this.getFileName(this.funderdetail.businessSnBackImgs),
            id: 0
          }]]
        } else {
          this.fileBusinessImgsList = []
        }
        if (this.funderdetail.fileDetailInfoVoList) {
          this.funderdetail.fileDetailInfoVoList.forEatch(item => {
            item.name = item.fileName
            item.src = item.fileUrl
          })
        }
        this.selectedOptionsList = this.funderdetail.fileDetailInfoVoList || []
      }).finally(() => {
        this.utils.closeLoading()
      })
    },
    getPageList () {
      const params = {
        businessId: this.funderdetail.enterpriseId,
        module: 'ORDER'
      }
      this.api.base.file.pageList(params).then(result => {
        this.selectedOptionsList = result.data.value.list || []
        this.selectedOptionsList.forEach(item => {
          item.src = item.fileUrl
          item.url = item.fileUrl
          item.name = item.fileName
        })
      }).finally(() => {
        this.utils.closeLoading()
      })
    },
    schemeList () {
      this.api.task.fundManagement.EnterpriseQuotationScheme(this.funderdetail.enterpriseId, 'modify').then(result => {
        this.accountInfoVoList = result.data.value || []
      }).finally(() => {
        this.utils.closeLoading()
      })
    },
    preview (file, detail) {
      if (file.fileType) {
        const fileTypeArr = file.fileType.split('/')
        if (fileTypeArr[0] !== 'image') {
          window.open(file.url)
        }
      }
    }
  }
}
</script>

<style scoped>
  .certificate-photo >>> .van-uploader__wrapper {
    display: inline-flex !important;
    flex-wrap: inherit !important;
  }
  .van-common-detail >>> .van-tabs .van-tabs__wrap .van-tabs__nav:first-child .van-tab{
    padding: 0 !important;
  }
</style>
